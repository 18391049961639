import React, { createContext, useState, ReactNode } from 'react';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: MainTextMatchedSubstrings[];
}

export interface PlaceType {
  currentOpeningHours: any;
  email: string | null;
  formatted_address: string | null;
  internationalPhoneNumber: string | null;
  name: string | null;
  opening_hours: any;
  place_id: string | null;
  rating: number | null;
  regularOpeningHours: any;
  types: string[] | null;
  user_ratings_total: number | null;
  utcOffsetMinutes: number | null;
  websiteUri: string | null;
}

interface PlaceContextProps {
  selectedPlace: PlaceType | null;
  setSelectedPlace: (place: PlaceType | null) => void;
}

export const PlaceContext = createContext<PlaceContextProps>({
    selectedPlace: null,
    setSelectedPlace:() => {}
});

export const PlaceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | null>(null);

  return (
    <PlaceContext.Provider value={{ selectedPlace, setSelectedPlace }}>
      {children}
    </PlaceContext.Provider>
  );
};
