import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { TermsContext } from '../Context/TermsContext';

const AcceptTerms: React.FC = () => {
  const { t } = useTranslation();
  const { acceptedTerms, setAcceptedTerms } = React.useContext(TermsContext);
  const [accept, setAccept] = React.useState(false);

  // React.useEffect(() => {
  //   setAccept( acceptedTerms );
  // }, [acceptedTerms, setAcceptedTerms]);


  const changeAccepted = (event: any, check:boolean) => {
    setAcceptedTerms(check);
    setAccept(check);
  };  

  return (
    <div className="accept-terms">
      <FormGroup>
        <FormControlLabel 
          required checked={accept} 
          onChange={changeAccepted} 
          control={<Checkbox />} 
          label={t('I accept the terms from Point.')} 
          sx={{ maxWidth: '240px' }}
        />
      </FormGroup>
    </div>
  );
};

export default AcceptTerms;
