const loadScript = (url: string, id: string): Promise<boolean> => {

    if (document.getElementById(id)) {
        return new Promise((resolve) => {
            resolve(true);
        });
    }

    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.crossOrigin = 'anonymous';
      script.src = url;
      script.id = id;
      script.async = true;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.head.appendChild(script);
    });
};
  
export default loadScript;
