import React from 'react';
import { getGeocode } from 'use-places-autocomplete';
import { TextField, IconButton, Typography, } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import '../index.css';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';
import i18n from '../i18n';
import { PlaceContext, PlaceType } from '../Context/PlaceContext';
import loadScript from '../Tools/loadScript';
import { LocationContext } from '../Context/LocationContext';
import axios from 'axios';

// https://developers.google.com/maps/documentation/places/web-service/place-types#table-a

const API = process.env.REACT_APP_API as string;

declare const window: {
  google:any;
  initPlaceService:any;
};

const PlacesSearchBar: React.FC<{ q: string }> = ({ q }) => {

  const { t } = useTranslation();

  const [inputValue, setInputValue] = React.useState('');
  const [places, setPlaces] = React.useState<PlaceType[]>([]);
  const { selectedPlace, setSelectedPlace } = React.useContext(PlaceContext);
  const { selectedLocation, setSelectedLocation } = React.useContext(LocationContext);
  const [queryUsed, setQueryUsed] = React.useState(false);

  const [loc, setLoc] = React.useState<google.maps.LatLng | null>(null);

  const loaded = React.useRef(false);
  const [placesLoaded, setPlacesLoaded] = React.useState(false);
  const placesService = React.useRef<google.maps.places.PlacesService | null>(null);

  const fetch = React.useMemo(
    () =>
      throttle(
        (request, callback: (results: google.maps.places.PlaceResult[] | null ) => void) => {

          request.componentRestrictions = {country: 'de'};
          request.language = i18n.resolvedLanguage;
          request.fields = [
              'addressComponents', 
              'displayName', 
              'formattedAddress', 
              'internationalPhoneNumber', 
              'location', 
              'name', 
              'openingHours', 
              'id', 
              'rating',
              'userRatingCount'
            ];
          // request.types = ['doctor', 'dentist', 'restaurant'];

          if (loc) {
            request.location = loc;
            request.radius = 1000;
          }

          // console.log("throttle: callback " + JSON.stringify(callback) + " request " + JSON.stringify(request));

          placesService.current?.textSearch(
            request,
            callback,
          );
        },
        200,
      ),
    [loc],
  );

  React.useEffect( () => {

    const URL = "https://maps.googleapis.com/maps/api/js?libraries=places&loading=async&callback=initPlaceService&key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    window.initPlaceService = () => {
      setPlacesLoaded(true);
    }

    loadScript(URL, "google-maps-api-script").then(
      async () => {
        loaded.current = true;
      }
    );

    let active = true;

    if (placesLoaded && !placesService.current && window.google) {

      placesService.current = new google.maps.places.PlacesService( document.createElement('div') );

      if (q !== null && q.length > 0 && !queryUsed) {

        const currentQ: PlaceType = {
          name: q,
          formatted_address: t('search...'),
          currentOpeningHours: undefined,
          email: null,
          internationalPhoneNumber: null,
          opening_hours: undefined,
          place_id: null,
          rating: null,
          regularOpeningHours: undefined,
          types: null,
          user_ratings_total: null,
          utcOffsetMinutes: null,
          websiteUri: null
        };
        setSelectedPlace(currentQ);
        setQueryUsed(true);
  
        fetch({ input: q }, (results: google.maps.places.PlaceResult[] | null ) => {
  
          if (results?.length && (results?.length > 0) && results[0].place_id !== null) {
            setSelectedPlace( results[0] as any );
          }
    
        });
      }
    }

    if (!placesService.current && window.google) {
      placesService.current = new google.maps.places.PlacesService( document.createElement('div') );
    }
    if (!placesService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setPlaces(selectedPlace ? [selectedPlace] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results: google.maps.places.PlaceResult[] | null ) => {

      if (active) {
        let newPlaces = [] as PlaceType[];

        if (selectedPlace) {
          newPlaces = [selectedPlace];
        }

        if (results) {

          newPlaces = [...newPlaces, ...results as any];
        }

        setPlaces(newPlaces);
      }
    });

    const loadAvailabilities = async () => {

      if (selectedPlace && selectedPlace.place_id && !selectedPlace.regularOpeningHours) {
      
        try {
  
          const response = await axios.post(API + '/availabilities', [{gpid: selectedPlace.place_id }]);
          // console.log('availabilities: ', response);

          if (response.data[0]?.place_id === selectedPlace.place_id ) {
            setSelectedPlace({...selectedPlace, ...response.data[0] as any});
          }

        } catch (e) {
          console.error(e);
        }
      }

      console.log('selectedPlace: ', selectedPlace);

    };

    loadAvailabilities();

    return () => {
      active = false;
    };
  }, [selectedPlace, setSelectedPlace, queryUsed, setQueryUsed, inputValue, fetch, q, t, placesLoaded, setPlacesLoaded, placesService]);

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const locs = new google.maps.LatLng({lat: latitude, lng: longitude});
          setLoc( locs );

          try {
            const results = await getGeocode({ location: { lat: latitude, lng: longitude } });

            let location:any = {

              formatted_address: results[0].formatted_address,
              street_number: results[0].address_components[0].long_name,
              route: results[0].address_components[1].long_name,
              postal_code: results[0].address_components[7].long_name,
              city: results[0].address_components[5].long_name,
              country: results[0].address_components[6].long_name,
              latitude:  latitude,
              longitude: longitude,
              uri: "https://www.google.com/maps/place/?q=place_id:" + results[0].place_id
            };
            setSelectedLocation(location);
            console.log("Location: ", location);

            console.log("selected Location: ", selectedLocation);

          } catch (error) {
            console.error("Error: ", error);
          }
        },
        (error) => {
          console.error("Error: ", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="place-search-bar">
    <Autocomplete
      id="google-map-api-textfield"
      getOptionLabel={(option:any) =>
        typeof option === 'string' ? option : option.name
      }
      filterOptions={(x) => x}
      options={places}
      autoComplete
      autoFocus
      freeSolo
      forcePopupIcon={false}
      fullWidth
      blurOnSelect
      includeInputInList
      onFocus={handleLocationClick}
      filterSelectedOptions
      value={selectedPlace}
      onChange={(event: any, newValue:any) => {

        if (typeof newValue === 'string' ) {

          const currentQ: PlaceType = {
            name: newValue, formatted_address: t('search...'),
            currentOpeningHours: undefined,
            email: null,
            internationalPhoneNumber: null,
            opening_hours: undefined,
            place_id: null,
            rating: null,
            regularOpeningHours: undefined,
            types: null,
            user_ratings_total: null,
            utcOffsetMinutes: null,
            websiteUri: null
          };

          setPlaces([currentQ]);

        } else {
          setPlaces(newValue ? [newValue, ...places] : places);
          if (newValue?.place_id) {
            setSelectedPlace(newValue);
          } else {
            setSelectedPlace(null);
          }
        }

      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} 
        label={t('Place')} 
        fullWidth 
        value={selectedPlace?.name}

        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <IconButton onClick={handleLocationClick}>
              <MyLocationIcon />
            </IconButton>
          ),
          style: { borderRadius: '10px' },
        }}
        />
      )}
      renderOption={(props, option) => {

        // console.log("renderOption: props " + JSON.stringify(props) + " option " + JSON.stringify(option));

        const matches = [option.name];
        const parts = parse(
          option.name,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props} key={option.place_id}>
            <Grid container alignItems="center">
              <Grid>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  <span>{option.name}</span>
                  <span
                    style={{
                      fontSize: '10px',
                      width: '100%'
                    }}                  
                  >{"- " + option.formatted_address}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
    </div>
  );

};

export default PlacesSearchBar;
