import React from 'react';
import PlacesSearchBar from '../Components/PlacesSearchBar';
import AppleCheckoutComponent from '../Components/AppleCheckoutComponent';
import GoogleCheckoutComponent from '../Components/GoogleCheckoutComponent';
import AppointmentTime from '../Components/AppointmentTime';
import { Box, Container, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PlaceContext } from '../Context/PlaceContext';
import { BookingContext } from '../Context/BookingContext';
import AcceptTerms from '../Components/AcceptTerms';
import { TermsContext } from '../Context/TermsContext';

// Test iframe:
//  <iframe allow='payment;geolocation;' height={'500'} width={'500'} src='https://point.rocks/Zahnarzt'></iframe>


const Home: React.FC = () => {

  const { t } = useTranslation();
  const { selectedPlace } = React.useContext(PlaceContext);
  const { selectedBooking } = React.useContext(BookingContext);
  const { acceptedTerms } = React.useContext(TermsContext);

  const {query = ''} = useParams<{ query?: string; }>();

  return (
    <div className="content">
      <Helmet>
        <title>{t("Book appointment at ") + query}</title>
        <meta name="description" content={t("Book appointment at ") + `${query}`} />
        <meta name="keywords" content={t("Appointment") + `, ${query}`} />
      </Helmet>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <p className='home-title'>
            {t("Book Appointment")}
          </p>
        <PlacesSearchBar q={query}/>
        <Fade in={selectedPlace != null} timeout={800} >
          <div>
            <AppointmentTime />
            <AcceptTerms />
            {!selectedBooking && acceptedTerms && <AppleCheckoutComponent/>}
            {!selectedBooking && acceptedTerms && <GoogleCheckoutComponent/>}
            {selectedBooking && <p style={{ textAlign:'center' }} >{t("Thank you for booking an appointment. Please check your mailbox.")}</p>}
          </div>
        </Fade>
        </Box>
      </Container>
    </div>
  );
};

export default Home;
