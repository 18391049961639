import { Dayjs } from 'dayjs';
import React, { createContext, useState, ReactNode } from 'react';

interface DateTimeContextProps {
  selectedDateTime: Dayjs | null;
  setSelectedDateTime: (date: Dayjs | null) => void;
}

export const DateTimeContext = createContext<DateTimeContextProps>({
    selectedDateTime: null,
    setSelectedDateTime:() => {}
});

export const DateTimeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedDateTime, setSelectedDateTime] = useState<Dayjs | null>(null);

  return (
    <DateTimeContext.Provider value={{ selectedDateTime, setSelectedDateTime }}>
      {children}
    </DateTimeContext.Provider>
  );
};
