import React, { createContext, useState, ReactNode } from 'react';

export interface LocationType {
  formatted_address: string | null;
  street_number: string | null;
  route: string | null;
  postal_code: string | null;
  city: string | null;
  country: string | null;
  latitude: string | null;
  longitude: string | null;
  uri: string | null;           // https://www.google.com/maps/place/?q=place_id:ChIJucyRgmuJsUcRQPZM62JlGTo
  email: string | null;
}

interface LocationContextProps {
  selectedLocation: LocationType | null;
  setSelectedLocation: (loc: LocationType | null) => void;
}

export const LocationContext = createContext<LocationContextProps>({
    selectedLocation: null,
    setSelectedLocation:() => {}
});

export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedLocation, setSelectedLocation] = useState<LocationType | null>(null);

  return (
    <LocationContext.Provider value={{ selectedLocation, setSelectedLocation }}>
      {children}
    </LocationContext.Provider>
  );
};
