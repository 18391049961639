import React from 'react';
import GooglePayButton from '@google-pay/button-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { DateTimeContext } from '../Context/DateTimeContext';
import { PlaceContext } from '../Context/PlaceContext';
import { BookingContext } from '../Context/BookingContext';
import CircularProgress from '@mui/material/CircularProgress';
import { Container } from '@mui/material';

const API = process.env.REACT_APP_API as string;

const GoogleCheckout: React.FC = () => {

  const { i18n } = useTranslation();
  const { selectedDateTime } = React.useContext(DateTimeContext);
  const { selectedPlace } = React.useContext(PlaceContext);
  const { setSelectedBooking } = React.useContext(BookingContext);
  const [loading, setLoading] = React.useState(false);

  // React.useEffect(() => {
  //   console.log("Google place: ", selectedPlace);
  //   console.log("Google date: ", selectedDateTime);
  // }, [selectedPlace, selectedDateTime]);

  const onLoadPaymentData = async ( paymentRequest: google.payments.api.PaymentData ) => {

    console.log('load payment data', paymentRequest);
    setLoading(true);

      let contact:any = {
        sender: {
          provider: "Google",
          apiversion: paymentRequest.apiVersion + "." + paymentRequest.apiVersionMinor,
          tokenization: JSON.parse( paymentRequest.paymentMethodData.tokenizationData.token ),
          name: paymentRequest.shippingAddress?.name,
          email: paymentRequest.email,
          phone: paymentRequest.shippingAddress?.phoneNumber,
          street: paymentRequest.shippingAddress?.address1,
          postal_code: paymentRequest.shippingAddress?.postalCode,
          city: paymentRequest.shippingAddress?.locality,
          country: paymentRequest.shippingAddress?.countryCode,
          latitude: null,
          longitude: null
        },
        event: {
          starttime: selectedDateTime?.toString()
        },
        place: selectedPlace
    };

      console.log("Contact: ", contact);

      try {

        const response = await axios.post(API + '/sendAppointment', contact);
        console.log('Sent appointment: ', response);
        setSelectedBooking(true);
          
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
  };

  return (
    <div style={{ width: '100%', maxWidth: '240px' }} className="google-pay-button">

      <GooglePayButton
      buttonLocale={i18n.resolvedLanguage}
         buttonType='book' 
         buttonRadius={10}
         buttonColor='default'

      existingPaymentMethodRequired={false}
      environment={process.env.REACT_APP_GOOGLE_PAY_ENVIRONMENT as google.payments.api.Environment}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"],
              assuranceDetailsRequired: false
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: "stripe",
                "stripe:version": "2018-10-31",
                "stripe:publishableKey": process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
                // gateway: 'example',
                // gatewayMerchantId: 'exampleGatewayMerchantId',
              },
            },
          },
        ],
        emailRequired: true,
        shippingAddressRequired: true,
        shippingAddressParameters: { allowedCountryCodes: ['DE'], phoneNumberRequired: true },
        merchantInfo: {
          merchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID as string,
          merchantName: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_NAME as string,          
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: '0.00',
          currencyCode: 'EUR',
          countryCode: 'DE',
        },
      }}
      onLoadPaymentData={onLoadPaymentData}

      onCancel={ (reason) => {
        setLoading(false);
        console.log('Google Pay cancelled ', reason);
      }}
      onError={ (error) => {
        setLoading(false);
        console.error('Google Pay error ', error);
      }}

    />

    {loading && 
      <Container maxWidth="lg" sx={{ mt: 6, mb: 6 }}>
        <CircularProgress
          color="primary"
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      </Container>    
    }
    </div>
  );
};

export default GoogleCheckout;
