import React from 'react';

export interface UserType {
  apiversion: string | null | undefined;
  tokenization: any | null | undefined;
  name: string | null | undefined;
  email: string | null | undefined;
  phone: string | null | undefined;
  latitude: string | null | undefined;
  longitude: string | null | undefined;
  street: string | null | undefined;
  postal_code: string | null | undefined;
  city: string | null | undefined;
  country: string | null | undefined;
}

interface UserContextProps {
  selectedUser: UserType | null;
  setSelectedUser: (user: UserType | null) => void;
}

export const UserContext = React.createContext<UserContextProps>({
    selectedUser: null,
    setSelectedUser:() => {}
});

export const useUserHelper = (user:UserType) => {
  
  const {selectedUser} = React.useContext(UserContext);
  const setSelectedUser = () => {
    console.log(selectedUser); 
  }
  return {setSelectedUser};
}

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedUser, setSelectedUser] = React.useState<UserType | null>(null);

  return (
    <UserContext.Provider value={{ selectedUser, setSelectedUser }}>
      {children}
    </UserContext.Provider>
  );
};
