import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import '../index.css';

const BottomNav: React.FC = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        navigate(newValue === 0 ? '/' : '/info');
      }}
      showLabels
      className="bottom-nav"
    >
      <BottomNavigationAction icon={<HomeIcon />} />
      <BottomNavigationAction icon={<InfoIcon />} />
    </BottomNavigation>
  );
};

export default BottomNav;
