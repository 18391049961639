import React from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { DateTimeContext } from '../Context/DateTimeContext';
import dayjs, { Dayjs } from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import i18n from '../i18n';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

const AppointmentTime: React.FC = () => {
  const { t } = useTranslation();
  const { selectedDateTime, setSelectedDateTime } = React.useContext(DateTimeContext);

  React.useEffect(() => {

    if (!selectedDateTime) {

      let tm = dayjs();
      tm = tm.set('second', 0);
      tm = tm.set('millisecond', 0);
      tm = tm.add(5,'minute');
      if (tm.minute() <= 30) {
        tm = tm.set('minute', 30);
      } else {
        tm = tm.set('minute', 0);
        tm = tm.add(1, 'hour');
      }

      setSelectedDateTime(tm);
    }
  }, [selectedDateTime, setSelectedDateTime])

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDateTime(newValue);
  };

  const isDateTimeDisabled = (date: Dayjs) => {
    // Example: Disable all dates before today and after 30 days from today
    const today = dayjs();
    const maxDate = today.add(365, 'day');
    return date.isBefore(today) || date.isAfter(maxDate);
  };

  return (
    <div className="date-time-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage} >
      <DateTimePicker
        label={t('Date & Time')}
        value={selectedDateTime ? dayjs(selectedDateTime) : null}
        onChange={handleDateChange}
        shouldDisableDate={isDateTimeDisabled}
        closeOnSelect
        disablePast
        minutesStep={30}
        displayWeekNumber
        showDaysOutsideCurrentMonth
        formatDensity='dense'
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock
        }}
      />
    </LocalizationProvider>
    </div>
  );
};

export default AppointmentTime;
