import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Privacy: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='plaintext'>
      <Helmet>
        <title>{t('Privacy') + " - Point"}</title>
      </Helmet>
      <h1>{t('Privacy')}</h1>
      <div dangerouslySetInnerHTML={{__html: t('PRIVACY')}} ></div>
    </div>
  );
};

export default Privacy;
