import React from 'react';

interface BookingContextProps {
  selectedBooking: boolean | null;
  setSelectedBooking: (book: boolean | null) => void;
}

export const BookingContext = React.createContext<BookingContextProps>({
    selectedBooking: null,
    setSelectedBooking:() => {}
});

export const BookingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedBooking, setSelectedBooking] = React.useState<boolean | null>(null);

  return (
    <BookingContext.Provider value={{ selectedBooking, setSelectedBooking }}>
      {children}
    </BookingContext.Provider>
  );
};
