import React from 'react';

interface TermsContextProps {
  acceptedTerms: boolean;
  setAcceptedTerms: (ac: boolean) => void;
}

export const TermsContext = React.createContext<TermsContextProps>({
    acceptedTerms: false,
    setAcceptedTerms:() => {}
});

export const TermsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);

  return (
    <TermsContext.Provider value={{ acceptedTerms, setAcceptedTerms }}>
      {children}
    </TermsContext.Provider>
  );
};
